/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed,  figure, figcaption, 
footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

pre,
pre[class*="language-"] {
  margin: 3rem 0;
  /* makes sure prismjs does not go in front of mobile navigation */
}

strong {
  font-weight: bold;
}


/* custom HTML */
$highlight-color: #3fbdfb;
$text-color: #2d2d2d;

html {
    font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;
    font-size: 1.1rem;
}

p {
   color: $text-color;
   margin-bottom: 2rem;
   line-height: 1.8rem;
}


em {
  font-style: italic;
}

ol {
  list-style: decimal;
}

ul {
  list-style: disc;
}

h1 {
   font-size: 3rem;
    font-weight: bold;
    margin-bottom: 3rem;
}

h2 {
	font-size: 2rem;
	margin-bottom: 1rem;
}

h2::before {
  content: " ";
	display: block;
	border-bottom: 1px solid #ececec;
	margin-bottom: 40px;
}

blockquote {
  background-color: rgb(232, 247, 255);
  border-left-color: #3db1df;
  border-left-width: 9px;
  border-left-style: solid;
  padding: 20px 45px 20px 26px;
  margin-bottom: 30px;
  margin-top: 20px;
  margin-left: -30px;
  margin-right: -30px;
}

blockquote p {
  margin-bottom: 0;
}

blockquote p:first-child {
  font-weight: bold;
}

button:hover {
  cursor: pointer;
}

hr {
  margin-top: 1rem;
  margin-bottom: 2rem;
}


nav {
  li {
    display: inline-block;
   }

   li a {
     padding: 1.25rem;
     display: inline-block;
     text-decoration: none;
     color: white;
   }

    li a:hover {
    color: #61dafb;
   }

   .branding-krita-name {
    margin: 0 1rem;
    margin-left: 0.3rem;
   }
   
   .branding-krita-slogan {
     color: #bbb;
     margin-right: 4rem;
   }

   &.mobile {
    display: none;
  }
  
}

.active-nav-link {
  border-bottom: 0.2rem solid #61dafb;
}
.active-lesson-nav-link {
  font-weight: bold;
  color: black;
}


.header-top-bar {
  background: #2d2d2d;
  position: fixed;
  width: 100%;
  z-index: 1;
}


.main-content-wrapper {
	margin: 3rem auto;
	width: 80%;
	max-width: 1920px;
  margin-top: 0;
  padding-top: 6rem;
}

.lessons-page,
.icon-library-page {
  display: grid;
  grid-template-columns:  auto 25rem;
}


.lessons-listing-container,
.icon-library-search-options-container {
  background: #eee;
  padding: 2rem;
  border-left: 0.07rem solid #d8d8d8;
  position: fixed;
  right: 0;
  top: 3.7rem;
  width: 17rem;
  height: calc(100vh - 7.7rem);
  overflow: scroll;
}
.lessons-listing-container a,
.lessons-listing-container a:visited {
  text-decoration: none;
  padding: 0.35rem;
  display: inline-block;
  color: #5b5e63;
  font-size: 0.8rem;
}

.lessons-listing-container a:hover {
  text-decoration: underline;
}



.lessons-listing-container li {
  margin-left: 1rem;
}


/* Styles related to indivividual lessons */
.lessons-item-container {
  ol, ul {
    margin: 3rem 3rem;
  
  }
  
  li {
    margin: 1rem 0;
    line-height: 150%;
  }
  
  img {
    max-width: 100%;
    margin: 1rem 0;
    border: 0.07rem solid grey;
  }
  
  h2 {
    font-size: 2.2rem;
    margin-bottom: 0.5rem;
  }
  
  h3 {
    font-size: 1.8rem;
    margin-top: 4rem;
    margin-bottom: 0.5rem;
    color: #696060;
  }
  
  h4 {
    margin-top: 2.7rem;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }
  
  a {
    background: #c5effd;
  
    color: black;
    text-decoration: none;
    border-bottom: 0.07rem solid black;
  }
  
  a:hover {
    border-bottom: 0;
    background: #91e2fd;
  }
}


.MuiToggleButton-label {
  text-transform: none;
}


/* Material component for Accordian Menu */
.lessons-listing-container {
  .MuiPaper-root.MuiAccordion-root {
    background: none;
    box-shadow: none;
  }
  .MuiAccordionSummary-root.Mui-expanded {
    min-height: auto;
  }
  .MuiButtonBase-root.MuiIconButton-root {
    padding: 0;
  }
  
  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  
  .MuiIconButton-root {
    font-size: 1rem;
  }


  ol, ul {
    list-style: none;
  }

  li {
    margin-left: 0;
  }



  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 0.3rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

.MuiToggleButtonGroup-root {
  margin: 0 1rem;

  button {
    color: white;
    border-color: #6a6a6a;
  }

  button.Mui-selected,
  button.Mui-selected:hover {
    background: $highlight-color;
    color: $text-color;
  }


}


.lessons-item-container {
  padding: 4rem;
  max-width: 42vw;
  margin: 0 auto;
  margin-top: 3rem;

  .lesson-breadcrumb {
    color: #928c8c;
    margin-bottom: 0.3rem;
    text-transform: uppercase;
    font-size: 0.8rem;
  }

  .lesson-overview {
    font-size: 18px;
    font-weight: 300;
    color: #6d6d6d;
  }
  

}


.lessons-title,
.selected-icon-title {
  font-size: 0.9rem;
  color: black;
}

.selected-icon-label {
 font-weight: bold;
 margin-top: 2.5rem;
 margin-bottom: 0.5rem;
}

.icon-library-icons-container {
  padding: 3rem;
  margin-top: 3rem;
}

.theme-checkbox-option {
  margin: 1rem 0;
}


#action-searchbox   {
  padding: 1rem;
  display: inline-block;
  min-width: 19%;
  margin-right: 1rem;
}
#icon-library-searchbox {
  padding: 1rem;
  display: block;
  margin-bottom: 1rem;
}


.next-previous-lesson-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  padding: 3rem 0;
  margin-top: 4rem;

  a {
    text-decoration: none;
    background: $highlight-color;
    padding: 1rem 2rem;
    color: white;
    border-radius: 2rem;
  
    background: rgb(63,189,251);
    background: linear-gradient(155deg, rgba(63,189,251,1) 0%, rgba(43,141,168,1) 100%);
  
    display: inline-block;
    margin-bottom: 1rem;
    border: none;

    box-shadow: 0.04rem 0.1rem 0.3rem rgb(95, 95, 158);
  }

  a:hover {
    background: rgb(91,201,255);
    background: linear-gradient(155deg, rgba(91,201,255,1) 0%, rgba(74,171,197,1) 100%); 
  }

  .next {
    justify-self: end;
    text-align: end;
  }

  .previous {
    justify-self: start;
  }

}



.help-container {
  padding: 3rem;
    background: #e8f7ff;

    h3 {
      padding-top: 0;
      margin-top: 0;
    }

    p {
      margin-bottom: 0
    }
}



.next em, 
.previous em {
  color: grey;
}



.icon-container {
  display: grid;
  /* repeat - https://developer.mozilla.org/en-US/docs/Web/CSS/repeat */
  // repeat(what_to_make-inline, each_element_width) last_element_width
  grid-template-columns: repeat(auto-fill,  150px) 150px;
  grid-gap: 0;
}


.actions-container {
  display: grid;
  grid-template-columns: repeat(auto-fill,  300px) 300px;
  grid-gap: 2rem;
  margin-top: 2rem;

  .action-item {
    line-height: 1.4rem;
    font-size: 0.8rem;

    div {
      opacity: 0.7;
    }
  
    div.action-item-title {
      font-size: 1.1rem;
      color: #020202;
      opacity: 1.0;
    }

  }



}


.icon-item {
  padding: 1rem;
  text-align: center;
  background: none;
  border: none;
  background: none;
  transition: background 0.5s ease;
  border-radius: 3px;
}
.icon-item:hover {
  background: #f1f1f1;
}

.icon-item-image {
	/* icon library image bg */
	padding: 1rem;
	margin-bottom: 0.5rem;
  border-radius: 0.22rem;
  
}
.icon-item-title {
	font-size: 0.8rem;
  color: #737373;
  word-break: break-word;
}

button.active-icon,
button.active-icon:active {
  border: 0.12rem solid #3fbcd1;
  outline: none;
}

.icon-library-page, .action-dictionary-page {
 padding-bottom: 1rem;
  min-height: 100vh;
}

/* Mobile styles below */

@media only screen and (max-width: 1000px) {

  blockquote {
    margin-left: 0;
    margin-right: 0;
  }

  /* hide the desktop navigation elements */
  .icon-library-search-options-container,
  .lessons-listing-container {
    display: none;
  }

  .lessons-page, .icon-library-page {
    grid-template-columns: auto;
  }

  .desktop-navigation {
    display: none;
  }

  .lessons-item-container {
    margin-top: 3rem;
    padding: 2rem;
    max-width: initial;
  }

  .main-content-wrapper {
    padding-top: 2rem;
  }

  .mobile-nav-icon {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  .active-nav-link  {
    color: #61dafb;
  }


  .next-previous-lesson-container {
    display: block;

    a {
      display: block;
      margin-bottom: 0.5rem;
      padding: 1.5rem;
      text-align: center;
    }
  }


  .next em,
  .previous em {
    margin-bottom: 2rem;
    text-align: center;
    display: block;
  }

  .header-top-bar {
    position: relative;
  }

  nav.mobile {
    display: block;
    bottom: 0;
    width: 100%;
    position: fixed;
    background: #2d2d2d;
    font-size: 0.8rem;
    z-index: 1;
  }

  nav.mobile ul {
    display: grid;
    grid-template-columns: auto auto auto;
    text-align: center;
  }

#action-searchbox {
  display: block;
  margin-bottom: 1rem;
}
  
  .actions-container {
    display: block;

    .action-item {
      margin: 2rem 0;
    }

  }

  .lessons-item-container,
  .icon-library-icons-container{
    margin-top: 0;
  }  

  pre[class*="language-"].line-numbers {
    max-width: 60vw;
  }

}

