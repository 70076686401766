.dark-theme {
    $highlight-color: #3fbdfb;
    $text-color: #dbdbdb;
    $text-color-alt: #cccccc;
    $background-color: #1b1b1b;
    $background-color-alt: #333333;
    $background-color-alt2: black;

    p, div, span, em, h1, h2, h3, h4, ol, ul, li,
    .lessons-item-container .lesson-overview,
    .lessons-title, .selected-icon-title,
    .actions-container .action-item div.action-item-title,
    .active-lesson-nav-link,
    .icon-item-image,
    .action-item-title{
        color: $text-color ;
    }

    input[type="text"] {
        background: $background-color-alt;
        color: $text-color-alt;
        border: none;
    }

    #icon-library-searchbox {
        background: $background-color;
    }

    blockquote {
        background: $background-color-alt2;
    }

    .MuiIconButton-root {
        color: $text-color-alt;
    }

    .lessons-listing-container a, .lessons-listing-container a:visited {
        color: $text-color-alt;
    }

    .lessons-listing-container, .icon-library-search-options-container {
        border-left: $background-color;
    }



    .lessons-page, .icon-library-page, .action-dictionary-page  {
        background: $background-color;
    }

    hr {
        border-color: $background-color
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: $background-color;
    }

        /* Handle */
    ::-webkit-scrollbar-thumb {
            background: $background-color-alt;
            border-radius: 0.3rem;
        }

    .lessons-listing-container, 
    .icon-library-search-options-container,
    .icon-item:hover {
        background: $background-color-alt;
    }

    .help-container {
        background:  $background-color-alt;
    }

}